.AuthorBio {
    width: 100%;
    padding-top: 50px;
    overflow: hidden;
}

.bio-content {
    display: flex;
    justify-content: space-between;
    max-width: 900px;
    margin: 0 auto;
    padding: 20px;
}

.AuthorBio .headshot-image {
    height: 355px;
    width: 256px;
    margin-top: 35px;
    background-image: url('../assets/headshot.jpg');
    background-size: contain;
    background-repeat: no-repeat;
}

.bio-text {
    max-width: 550px;
}

@media screen and (max-width: 920px) {
    .bio-content {
        height: 100%;
        flex-direction: column;
    }
    
    .AuthorBio {
        overflow: auto;
    }
    
    .AuthorBio .headshot-image {
        margin: 0 auto;
    }
}