.NewsContent {
    padding: 25px;
    height: 100%;
    overflow-y: auto;
}

.news-element-wrapper {
    box-shadow:  0em 0em 1.5px rgba(255, 255, 255, 0.25);
}

.news-element-wrapper h3 {
    width: 100%;
    text-align: left;
    line-height: 0;
}

.news-element-wrapper h5 {
    width: 100%;
    text-align: left;
    line-height: 1em;
}

.news-body {
    height: 100%;
}

.link-container {
    cursor: pointer;
    margin-bottom: 15px;
}

.link-container span {
    color: #0099FF;
    font-weight: bold;
}