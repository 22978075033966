.BookList {
    width: 100%;
    padding-top: 50px;
    overflow: hidden;
}

.books-container {
    display: flex;
    flex-direction: column;
}

.books-container h2 {
    margin-bottom: 20px;
}

.book-element-wrapper {
    display: flex;
    flex-direction: row;
    margin: 0 auto 50px auto;
}

.cover-image {
    width: 185px;
    height: 270px;
    background-size: contain;
    background-repeat: no-repeat;
    border: 1px solid grey;
    margin-right: 25px;
}

.book-info {
    max-width: 500px;
}

.button-container {
    display: flex;
    justify-content: space-around;
}

.button-container button {
    max-width: 300px;
    height: 35px;
    border: 1px solid gray;
    cursor: pointer;
}

.button-container button:hover {
    background-color: #0099FF;
    border: 1px solid #0099FF;
}

@media screen and (max-width: 920px) {
    .book-element-wrapper {
        flex-direction: column;
        margin: 0 auto;
        padding: 0 20px;
    }
    
    .cover-image {
        margin: 0 auto;
    }
}