.ViewBox {
    overflow: hidden;
    height: 100%;
}

.content-container {
    overflow: hidden;
    height: 100%;
    position: relative;
    display: flex;
}

.news-container {
    margin-top: 20px;
    width: 33%;
    padding: 5px;
    overflow: auto;
    height: 100%;
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
    background-image:
    linear-gradient(
      #FFFFFF 50%, #0099FF
    );;
}

.news-container::-webkit-scrollbar {
    display: none;
}

.dynamic-content {
    width: 100%;
    overflow: auto;
    height: auto; 
}

.desktop-only {
    display: block;
}

@media screen and (max-width: 920px) {
    .desktop-only {
        display: none;
    }
    
    .ViewBox {
        overflow: auto;
        padding-bottom: 200px;
    }
    
    .content-container {
        flex-direction: column;
        position: relative;
        overflow: auto;
    }
    
    .dynamic-content {
        position: relative;
        top: 0px;
        overflow: auto;
    }
    
    .news-container {
        width: 100%;
        position: relative;
        bottom: 0px;
    }
    
}
