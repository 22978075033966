
.HeaderBox {
    width: 100%;
    height: 125px;
    display: flex;
}

.qwertychimp-logo {
    height: 204px;
    width: 534px;
    background-image: url('../assets/QwertyChimpLogo.png');
    background-size: contain;
    background-repeat: no-repeat;
}

.menu-container {
    display: flex;
    justify-content: space-around;
    width:100%;
    padding: 10px;
    padding-top: 50px;
    height: 60px;
}

.nav-button {
    display: flex;
    align-items: center;
    justify-content: space-around;
    cursor: pointer;
    height: 35px;
    width: 100px;
    border-radius: 20px;
    border: 1px solid gray;
    background-color: #FFFFFF;
}

.nav-button span {
    display: inline-block;
    font-size: 0.8em;
    line-height: 1.1em;
    color: #000044;
}

.nav-button:hover {
    background-color: #0099FF;
    border: 1px solid #0099FF;
}

.nav-button:hover span {
    color: #ffffff;
}

.mobile-only {
    display: none;
}

@media screen and (max-width: 920px) {
    .mobile-only {
        display: block;
    }
    
    .news-pill {
        display: flex;
        align-items: center;
    }
    
    .HeaderBox {
        flex-direction: column;
        height: auto;
    }
    
    .qwertychimp-logo {
        background-position: center center;
        width: 100%;
        height: 115px;
    }
    
    .menu-container {
        height: auto;
        flex-wrap: wrap;
        justify-content: space-between;
        width:100%;
        padding: 0px 10px;
        padding-top: 0px;
    }
    
    .nav-button {
        justify-content: space-around;
        cursor: pointer;
        height: 25px;
        width: 80px;
        margin-bottom: 5px;
    }
    
    .nav-button span {
        display: inline-block;
        font-size: 0.5em;
        line-height: 1em;
        color: #000044;
        font-weight: bold;
    }
}
