.ShortStories {
    padding-top: 10px;
}

.story-flex-grid {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    padding: 25px;
}

.copyright-message {
    font-size: 10px;
    font-style: italic;
    font-weight: 600;
}

.story-link-wrapper {
    max-width: 275px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px 10px;
}

.story-title {
    line-height: 1;
    margin: 0;
}

.story-blurb  {
    text-align: left;
}

.story-link {
    text-decoration: none;
}

.divider {
    display: none;
}

@media screen and (max-width: 920px) {
    .story-link-wrapper {
        max-width: 100%;
        margin-bottom: 20px;
    }
    
    .story-flex-grid {
        flex-direction: column;
    }
    
    .divider {
        display: block;
        width: 100%;
        margin-top: 20px;
    }
}