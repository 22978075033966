.OtherAuthors {
    height: 100%;
    width: 100%;
    padding-top: 50px;
    overflow: hidden;
}

.OtherAuthors h4, .author-info h2{
    line-height: 0;
}

.authors-container {
   height: 100%;
}

.authors-container h2 {
    margin-bottom: 20px;
}

.author-element-wrapper {
    display: flex;
    flex-direction: row;
    max-width: 900px;
    margin: 0 auto 50px auto;
}

.OtherAuthors .headshot-image {
    width: 185px;
    height: 270px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    margin-right: 25px;
}

.author-info {
    max-width: 500px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.author-info p, .book-link-container h4, .author-bio {
    text-align: left;
}

.book-link-container {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.book-links {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-start;
}

.book-links span {
    display: inline-block;
    font-weight: bold;
    color: #0099FF;
    cursor: pointer;
}

.book-links h5 {
    text-decoration: underline;
}

@media screen and (max-width: 920px) {
    .authors-container {
       overflow: auto;
    }
    
    .author-element-wrapper {
        flex-direction: column;
        margin: 0 auto;
        padding: 0 20px;
    }
    
    .OtherAuthors .headshot-image {
        margin: 0 auto;
    }
}